@media (min-width: 320px) and (max-width: 767px) {
    .login-logo-div {
        padding: 0 0 15px;
        display: flex;
        align-items: center;
        justify-content: center
    }

    .login-logo-div img {
        height: 90px
    }

    .login-details-inner {
        width: 100%;
        margin: 0;
        padding-left: 0
    }

    .login-logo-details h5 {
        font-size: 36px
    }

    .login-logo-details h6 {
        font-size: 14px;
        line-height: 0
    }

    .login-logo-details .main-form-div h6 {
        font-size: 18px;
        line-height: 14px
    }

    .main-form-div {
        padding: 30px 5px
    }

    .main-form-div .form-control, .ant-input {
        height: 45px;
        font-size: 15px
    }

    .main-form-div label {
        top: 12px
    }

    .login-main-fluid {
        height: 100% !important
    }

    .main-sidebar-div.ant-layout-sider {
        left: -100%;
        z-index: 9999
    }

    .logo {
        height: 30px
    }

    .main-sidebar-div .ant-layout-sider-trigger {
        position: fixed;
        top: 10px;
        left: 15px;
        background: #eee;
        box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.01);
        width: 35px !important;
        padding: 0 6px;
        height: 35px
    }

    .row.heading-header-row h5 {
        font-size: 18px;
        line-height: 26px
    }

    .ant-modal {
        width: 80% !important
    }

    .site-layout.site-header-custom {
        right: 0;
        width: 100%;
        z-index: 999;
        padding: 0
    }

    .main-sidebar-div.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger {
        left: 0
    }

    .header-details-left h5 {
        font-size: 18px
    }

    .header-details-left .ant-picker-range {
        width: 52%
    }

    .header-details-left .ant-btn.filter-green-btn {
        width: 55px;
        font-size: 12px;
        padding: 0
    }

    .header-details-right {
        padding-top: 10px
    }

    .header-details-right .ant-input-affix-wrapper {
        width: 78%;
        margin-right: 10px
    }

    .site-layout .ant-layout-header {
        height: 100px;
        padding: 10px 15px
    }

    .header-details-left {
        padding-left: 45px
    }

    .site-layout-main .ant-layout-content {
        padding: 30px 15px;
        width: 100%;
        margin-top: 100px
    }

    .listing-table .ant-table thead > tr > th {
        font-size: 14px;
        line-height: 10px
    }

    .listing-table .ant-table-tbody tr td {
        font-size: 12px;
        line-height: 14px
    }

    .main-sidebar-div.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger ~ .site-layout.site-header-custom {
        width: 100%
    }

    .ant-layout-main-second .ant-layout-content {
        margin-top: 30px
    }

    .ant-modal-footer.footer-custom {
        width: 100%;
        padding: 10px 15px
    }

    .title-details-row p {
        font-size: 14px
    }

    .main-sidebar-div.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger ~ .site-layout-main .ant-layout-content {
        width: 100%;
    }


    .main-sidebar-div.small-sidebar .ant-layout-sider-trigger:before, .main-sidebar-div.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger.small-sidebar .ant-layout-sider-trigger:before {
        display: none;
    }
    .main-sidebar-div.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger.small-sidebar .ant-layout-sider-trigger:after {
        background-image: url("../../assets/img/cross-icon.png");
    }
    .main-sidebar-div.small-sidebar .ant-layout-sider-trigger:after {
        background-image: url("../../assets/img/Hamburger.png");
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        bottom: 0;
        right: 0;
        margin: auto;
    }
}

@media (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
    .login-details-inner {
        width: 70%;
        margin: 0 auto
    }

    .ant-layout-main-second .ant-layout-content {
        margin-top: 0
    }

    .login-main-fluid {
        height: auto !important
    }

    .header-details-left {
        padding-left: 40px
    }

    .site-layout .ant-layout-header {
        height: 70px
    }

    .main-sidebar-div .ant-layout-sider-trigger {
        top: 10px
    }

    .site-layout-main .ant-layout-content {
        margin-top: 70px
    }

    .main-sidebar-div.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger ~ .site-layout.site-header-custom {
        width: 88%
    }

    .main-sidebar-div.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger ~ .site-layout.site-header-custom .header-details-left {
        padding-left: 0
    }

    .main-sidebar-div.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger ~ .site-layout.site-header-custom .row.heading-header-row h5 {
        padding-left: 0
    }

    .ant-layout-main-second .ant-layout-content {
        margin-top: 0
    }

    .row.heading-header-row h5 {
        padding-left: 45px
    }
}

@media (min-width: 768px) and (max-width: 1200px) {
    .login-main-fluid {
        /* padding: 5% */
        background: #ffffff;
    }

    .login-main-fluid > .row {
        margin: 0;
        align-items: center
    }

    .login-logo-div {
        padding: 30% 0 0 0
    }

    .login-logo-div img {
        height: 90px
    }

    .login-details-inner {
        width: 100%;
        padding-left: 0;
        margin: 0
    }

    .login-logo-details h5 {
        font-size: 42px
    }

    .login-logo-details h6 {
        font-size: 18px
    }

    .site-layout.site-header-custom {
        width: 82.6%
    }

    .site-layout-main .ant-layout-content {
        padding: 30px;
        width: calc(100% - 130px)
    }

    .header-details-left .ant-btn.filter-green-btn {
        width: 70px;
        padding: 0
    }

    .header-details-left h5 {
        font-size: 16px
    }

    .header-details-left .ant-picker-range {
        width: 50%;
        padding: 0 5px;
    }
    .header-details-right .ant-input-affix-wrapper {
        width: 72%;
        margin-right: 20px
    }

    .main-sidebar-div.ant-layout-sider {
        flex: 0 0 130px !important;
        max-width: 130px !important;
        min-width: 130px !important;
        width: 130px !important
    }

    .listing-table .ant-table-tbody tr td {
        font-size: 12px;
        line-height: 10px
    }

    .main-sidebar-div .ant-layout-sider-trigger {
        left: 5px;
        width: 40px !important
    }

    .main-sidebar-div .ant-menu.ant-menu-dark .ant-menu-item a {
        font-size: 14px;
        padding: 0 15px 0 40px !important
    }

    .checkbox-span-tag {
        width: 15px;
        height: 15px
    }

    .sub-menu-div-inner .ant-checkbox-wrapper, .ant-menu-submenu.ant-menu-submenu-popup.ant-menu-dark .ant-checkbox-wrapper {
        width: 100%
    }

    .main-sidebar-div.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger {
        flex: 0 0 80px !important;
        max-width: 80px !important;
        min-width: 80px !important;
        width: 80px !important
    }

    .main-sidebar-div.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger ~ .site-layout.site-header-custom {
        width: 89.6%
    }

    .ant-modal-footer.footer-custom {
        width: 83.1%
    }

    .ant-modal {
        width: 45% !important
    }

    .sub-menu-div-inner a {
        width: 100%
    }
}

@media (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
    /* .login-logo-div {
        padding: 0;
    } */
}