:root {
    --primary-color: #57B5A4;
    --body-color: #EDF1F4;
    --off-black-color: #182027;
    --gray-color: #818E9B;
    --off-white: #EDF1F4;
    --gray-1-color: #373948;
    --gray-2-color: #6A6C7C;
}

body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', 'Montserrat', sans-serif;
    background-color: var(--white);
}

.pad-0 {
    padding: 0px;
}

.App, #root {
    height: 100%;
    text-align: left;
}

button, a {
    cursor: pointer;
    transition: 0.3s ease all;
}

h1, h2, h3, h4, h5, h6 {
    margin-bottom: 15px;
}

.flex-align-center {
    display: flex !important;
    align-items: center !important;
}

.flex-align-center-center {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.flex-align-center-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/*--login-css--*/
.login-main-fluid {
    /* padding: 5% 10%; */
    background-color: var(--body-color);
}

.login-logo-div {
    /* padding: 5.5% 0 0 2.5%; */
    background-color: #182027;
}

.login-logo-div img {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
}

.login-logo-details {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Montserrat, sans-serif;
}

.form-row {
    width: 50%;
    margin: auto !important;
}

.form-row h6 {
    text-align: left !important;
}

.login-container .form-items input {
    border-bottom: 1px solid #999999 !important;
    border: 0px;
    border-radius: 0px;
    outline: none;
    height: 30px;
    padding-left: 6px;
}

.login-container .form-items input:focus {
    box-shadow: none;
}

.login-logo-details h5, .login-logo-details h6 {
    font-family: Montserrat, sans-serif;
    color: var(--off-black-color);
}

.login-logo-details h5 {
    font-size: 53px;
    line-height: 65px;
    letter-spacing: 0.07em;
}

.login-logo-details h6 {
    font-size: 20px;
    line-height: 18px;
    letter-spacing: 0.2em;
}

.login-details-inner {
    width: 91%;
    padding-left: 4%;
}

.main-form-div {
    box-shadow: 0 1px 40px rgba(24, 32, 39, 0.12), 0 2px 20px rgba(24, 32, 39, 0.06);
    border-radius: 20px;
    padding: 30px 15px;
}

.login-logo-details .main-form-div h6 {
    font-family: Roboto, sans-serif;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0;
    font-weight: 800;
}

.form-items .form-group button {
    background: #5D94CF;
    border-radius: 3px;
}

.form-items .form-group {
    text-align: left;
    margin: 0px;
}

.form-items .form-group label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #000;
}

.login-logo-details .ant-form-item-explain {
    text-align: left;
}

.main-form-div .ant-form-item {
    margin-bottom: 26px;
}

.main-form-div .ant-col {
    width: 100%;
    text-align: left;
}

.main-form-div label {
    color: var(--off-black-color);
    margin-bottom: 0;

}

/*            .main-form-div .form-control:focus ~ label,*/
/*.main-form-div .form-control:not(:placeholder-shown) ~ label {*/
/*    top: -12px;*/
/*    background-color: var(--white);*/
/*    padding: 0 5px;*/
/*    color: var(--off-black-color);*/
/*}*/
.main-form-div .form-control:focus {
    box-shadow: none;
    outline: 0;
    border-color: var(--off-black-color);
}

.main-form-div .form-control, .ant-input {
    border: 1px solid var(--gray-color);
    border-radius: 8px;
    height: 56px;
    letter-spacing: 0.03125rem;
    font-size: 16px;
    line-height: 20px;
    font-family: Roboto, sans-serif;
}

.header-details-right .ant-input {
    height: 34px;
}

.header-details-right .hdr-toggle-main {
    position: relative;
}

.hdr-toggle-main .ant-switch-checked .ant-switch-handle {
    height: 35px;
    width: 125px;
    left: auto;
    right: 0;
}

.hdr-toggle-main .ant-switch-checked .ant-switch-handle::before {
    left: auto;
    right: 0;
}

.hdr-toggle-main .ant-switch-handle::before {
    height: 35px;
    width: 125px;
    left: 0;
    transition: 2s;
}

.header-details-right .hdr-list-icon {
    position: absolute;
    left: 11px;
    top: 25px;
}

.header-details-right .hdr-chart-icon {
    position: absolute;
    right: 30px;
    top: 23px;
}

.main-form-div .small-size .form-control {
    height: 40px;
}

.main-form-div form .small-size label {
    top: 10px;
}

/*.main-form-div .form-control, .main-form-div .form-control::-webkit-input-placeholder {*/
/*    color: var(--off-black-color);*/
/*}*/
.main-form-div .ant-btn {
    background-color: var(--gray-color);
    border-radius: 7px;
    height: 48px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
}

.main-form-div .ant-btn:hover {
    background-color: var(--primary-color);
}

/*--sidebar--*/
.main-sidebar-div.ant-layout-sider {
    top: 0;
    left: 0;
    overflow: auto;
    background-color: var(--off-white);
}

.main-sidebar-div .ant-layout-sider-trigger {
    background: transparent;
    color: var(--gray-color);
    position: absolute;
    top: 18px;
    left: 20px;
    width: 45px !important;
    padding: 0 10px;
}

.main-sidebar-div .ant-layout-sider-trigger:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('../../assets/img/cross-icon.png');
    width: 20px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    right: 0;
    margin: auto;
}

.main-sidebar-div.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger .ant-layout-sider-trigger:before {
    background-image: url('../../assets/img/Hamburger.png');

}

.main-sidebar-div.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger .add-btn-div .ant-btn {
    text-align: center !important;
    justify-content: center;
}

.main-sidebar-div.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger .add-btn-div .ant-btn span {
    display: none;
}

.main-sidebar-div.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger .add-btn-div .ant-btn span.plus-tag {
    display: block;
    margin: 0 !important;
}

.main-sidebar-div .ant-layout-sider-trigger .anticon.anticon-left,
.main-sidebar-div.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger .ant-layout-sider-trigger .anticon.anticon-right {
    display: none;
}

.main-sidebar-div .ant-menu.ant-menu-dark {
    background-color: var(--off-white);
}

.logo {
    height: 80px;
}

.add-btn-div .ant-btn {
    color: var(--primary-color);
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.04em;
}

.add-btn-div .ant-btn .plus-tag {
    box-shadow: 0 1px 3px rgba(24, 32, 39, 0.2), 0 2px 2px rgba(24, 32, 39, 0.12), 0 0 2px rgba(24, 32, 39, 0.14);
    border-radius: 15px;
    width: 28px;
    height: 28px;
    margin-right: 10px;
}

.menu-div-main {
    border-bottom: 1px solid var(--white);
}

.main-sidebar-div .ant-menu.ant-menu-dark .ant-menu-item {
    margin: 0;
}

.main-sidebar-div .ant-menu.ant-menu-dark .ant-menu-item a {
    color: var(--off-black-color);
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.5px;
    height: 40px;
    padding: 0 16px 0 50px !important;
}

.main-sidebar-div .ant-menu.ant-menu-dark .ant-menu-item.ant-menu-item-selected {
    background-color: transparent;
}

.main-sidebar-div .ant-menu.ant-menu-dark .ant-menu-item.ant-menu-item-selected a, .main-sidebar-div .ant-menu.ant-menu-dark .ant-menu-item a:hover {
    color: var(--primary-color);
}

.checkbox-span-tag {
    width: 20px;
    height: 20px;
    border-radius: 2px;
    border: 1px solid var(--off-black-color);
    left: 16px;
    top: 0;
    bottom: 0;
}

.main-sidebar-div .ant-menu.ant-menu-dark .ant-menu-item.ant-menu-item-selected a .checkbox-span-tag,
.main-sidebar-div .ant-menu.ant-menu-dark .ant-menu-item a:hover .checkbox-span-tag {
    border-color: var(--primary-color);
    background-color: var(--primary-color);
}

.sub-menu-div-inner .ant-menu-dark.ant-menu-inline .ant-menu-item,
.sub-menu-div-inner .ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
    color: var(--gray-color);
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    padding: 0 16px 0 35px !important;
    display: flex;
    align-items: center;
}

.sub-menu-div-inner .ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow,
.sub-menu-div-inner .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {
    opacity: 1;
    right: auto;
    left: 16px;
    color: var(--gray-color);
}

.sub-menu-div-inner .ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.sub-menu-div-inner .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.sub-menu-div-inner .ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.sub-menu-div-inner .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:after {
    background-color: var(--gray-color);
    width: 7px;
    height: 2.5px;
}

.sub-menu-div-inner .ant-menu-dark .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
.sub-menu-div-inner .ant-menu-dark .ant-menu-sub.ant-menu-submenu-active .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
.sub-menu-div-inner .ant-menu-dark .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.sub-menu-div-inner .ant-menu-dark .ant-menu-sub.ant-menu-submenu-active .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after {
    background-color: var(--gray-color);
}

.sub-menu-div-inner .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background-color: transparent;
    padding: 0 16px;
}

.sub-menu-div-inner .ant-checkbox-wrapper, .ant-menu-submenu.ant-menu-submenu-popup.ant-menu-dark .ant-checkbox-wrapper {
    color: var(--gray-color);
    letter-spacing: 0.25px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    padding: 6px 0;
    width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
}

.ant-menu-submenu.ant-menu-submenu-popup.ant-menu-dark {
    width: 225px;
}

.ant-menu-submenu.ant-menu-submenu-popup.ant-menu-dark,
.ant-menu.ant-menu-sub.ant-menu-vertical {
    background-color: var(--off-white) !important;
}

.ant-menu.ant-menu-sub.ant-menu-vertical {
    padding: 15px;
}

.sub-menu-div-inner a.active {
    background-color: var(--off-white);
}

.sub-menu-div-inner .ant-checkbox {
    margin-right: 10px;
}

.sub-menu-div-inner .ant-checkbox-inner {
    width: 13px;
    height: 13px;
    border-radius: 2px;
    background: transparent;
    border: 1px solid var(--gray-color);
}

.sub-menu-div-inner .ant-checkbox-wrapper-checked .ant-checkbox-inner,
.sub-menu-div-inner .ant-checkbox:hover .ant-checkbox-inner,
.ant-menu-submenu.ant-menu-submenu-popup.ant-menu-dark .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-menu-submenu.ant-menu-submenu-popup.ant-menu-dark .ant-checkbox:hover .ant-checkbox-inner,
.ant-menu-submenu.ant-menu-submenu-popup.ant-menu-dark .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: var(--primary-color);
    background-color: var(--primary-color);
}

.sub-menu-div-inner .ant-checkbox-checked::after,
.ant-menu-submenu.ant-menu-submenu-popup.ant-menu-dark .ant-checkbox-checked::after {
    border-color: var(--primary-color);
}

.sub-menu-div-inner .tag-apple-type {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    left: 18px;
    top: 0;
    bottom: 0;
}

/*--header--*/
.site-layout.site-header-custom {
    right: 0;
    width: calc(100% - 200px);
    z-index: 999;
}

.site-layout .ant-layout-header {
    height: 75px;
    padding: 0 30px;
    background-color: var(--white);
    z-index: 999;
}

.header-details-left h5 {
    font-size: 24px;
    line-height: 36px;
    color: var(--gray-1-color);
}

.header-details-left .ant-picker-range {
    border-radius: 8px;
    border: 1px solid var(--gray-color);
    height: 36px;
    width: 54%;
}

/*.header-details-left .ant-picker-input input, .header-details-left .ant-picker-input input::-webkit-input-placeholder {*/
/*    color: var(--gray-color);*/
/*}*/
.header-details-left .ant-picker-suffix {
    top: -3px;
    position: relative;
}

.header-details-left .ant-btn.filter-green-btn {
    height: 36px;
    width: 82px;
    border-radius: 7px;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    font-size: 14px;
    line-height: 20px;
}

.header-details-left .ant-btn.filter-green-btn:hover {
    color: var(--white);
    background-color: var(--primary-color);
}

.header-details-right .ant-input-affix-wrapper {
    border: 1px solid var(--off-white);
    border-radius: 25px;
    height: 36px;
    padding: 0 16px 0 50px;
    width: 52%;
    margin-right: 30px;
}

.header-details-right .ant-input-affix-wrapper .ant-input, .header-details-right .ant-input-affix-wrapper .ant-input::-webkit-input-placeholder {
    /*color: var(--gray-color);*/
    font-size: 14px;
    /*line-height: 16px;*/
}

.header-details-right .ant-input-affix-wrapper .ant-input-suffix {
    right: auto;
    left: 9px;
    margin: 0;
    position: absolute;
    top: 9px;
    font-size: 18px;
}

.header-details-right .ant-input-affix-wrapper .ant-input-suffix span.anticon::before {
    display: none !important;
}

.header-details-right .ant-dropdown-link:focus {
    outline: 0;
    box-shadow: none;
}

/*--dashboard--*/
.site-layout-main .ant-layout-content {
    width: calc(100% - 200px);
    float: right;
    margin-top: 75px;
    padding: 30px 60px;
    height: 100%;
}

.listing-table .ant-table thead > tr > th {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: var(--off-black-color);
    background-color: transparent;
    border-top: none;
    border-color: var(--off-white);
}

.listing-table .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 0;
}

.listing-table .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 0;
}

.listing-table .ant-table-tbody tr td {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    border-top: none;
    border-color: var(--off-white);
}

.gray-color-custom {
    color: var(--gray-color);
}

.tag-div {
    width: 8px;
    height: 8px;
    background-color: #BB7EF5;
    left: 0;
    top: 0;
    bottom: 0;
}

.tag-div.tag-w-h-inc {
    width: 12px;
    height: 12px;
}

.tag-div.tag-color-div-second {
    left: 10px;
}

.modal-row-main {

}

.ant-modal-body .modal-row-main .ant-select {
    width: 100%;
    margin-bottom: 20px;
}

.modal-row-main .ant-select .ant-select-selector {
    border: 1px solid var(--gray-color);
    border-radius: 8px;
    height: 40px;
}

.modal-row-main .ant-select .ant-select-selector .ant-select-selection-search {
}

.modal-row-main .ant-select .ant-select-selector .ant-select-selection-search .ant-select-selection-search-input {
    height: 38px;
}

.modal-row-main .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 40px;
    font-weight: normal;
    font-size: 14px;
    color: var(--gray-color);
    opacity: 1;
}

.ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 35px;
}

.ant-modal-content {
    border-radius: 20px;
    padding: 20px 0 10px;
}

.ant-modal-header {
    border-radius: 20px 20px 0 0;
    text-align: center;
    border: none;
}

.ant-modal-title {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: var(--off-black-color);
}

.ant-modal-footer {
    border: none;
}

.ant-modal-footer .ant-btn {
    background-color: transparent;
    border: 1px solid transparent;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.01em;
    color: var(--gray-2-color);
    text-transform: uppercase;
    border-radius: 7px;
    height: 36px;
}

.ant-modal-footer .ant-btn:hover {
    border-color: var(--gray-2-color);
}

.ant-modal-footer .ant-btn.ant-btn-primary {
    color: var(--white);
    background: var(--gray-color);
    opacity: 0.3;
}

.ant-modal-footer .ant-btn.ant-btn-primary:hover {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    opacity: 1;
}

.ant-layout-main-second .ant-layout-content {
    margin-top: 0;
}

.row.heading-header-row {
    padding-bottom: 30px;
}

.row.heading-header-row h5 {
    font-size: 24px;
    line-height: 36px;
    color: var(--gray-1-color);
}

.row.heading-header-row .ant-btn {
    color: var(--primary-color);
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
}

.title-details-row h6 {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--off-black-color);
}

.title-details-row h6.list-heading-div {
    font-size: 20px;
    line-height: 24px;
}

.title-details-row p {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: var(--gray-color);
}

.title-details-row .ant-checkbox-checked .ant-checkbox-inner {
    border-color: var(--primary-color);
    background-color: var(--primary-color);
}

.title-details-row .ant-checkbox-checked::after {
    border-color: var(--primary-color);
}

.ant-modal-footer.footer-custom {
    width: calc(100% - 200px);
    position: fixed;
    bottom: 0;
    right: 0;
    border-top: 1px solid var(--off-white);
    z-index: 99;
    padding: 10px 60px;
}

.ant-select-item {
    padding: 7px 15px;
    font-size: 16px;
    line-height: 20px;
    color: var(--off-black-color)
}

.ant-select-item-option-content {
    font-size: 14px;
}

.icon-date-picker {
    right: 5px;
    top: 7px;
}

.edit-item-btn {
    font-size: 16px;
    line-height: 20px;
    color: var(--off-black-color)
}

body .ant-modal-wrap {
    z-index: 999999999;
}

.modal-row-main .ant-select.account-color-div .ant-select-selector {
    padding-left: 25px;
}

.main-sidebar-div.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger {
}

.main-sidebar-div.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger .logo {
    opacity: 0;
}

.main-sidebar-div.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger .ant-menu.ant-menu-dark .ant-menu-item a {
    padding: 0 !important;
}

.main-sidebar-div.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger .ant-menu.ant-menu-dark .ant-menu-item a .text {
    display: none;
}

.main-sidebar-div.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger .ant-menu-inline-collapsed > .ant-menu-item,
.main-sidebar-div.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.main-sidebar-div.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
.main-sidebar-div.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title,
.main-sidebar-div.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger .ant-menu.ant-menu-dark .ant-menu-item {
    padding: 0 12px !important;
}

.main-sidebar-div.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger .ant-menu-dark,
.main-sidebar-div.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger .ant-menu-dark .ant-menu-sub,
.main-sidebar-div.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger .ant-menu-dark .ant-menu-item:hover,
.main-sidebar-div.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger .ant-menu-dark .ant-menu-item-active,
.main-sidebar-div.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger .ant-menu-dark .ant-menu-submenu-active,
.main-sidebar-div.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger .ant-menu-dark .ant-menu-submenu-open,
.main-sidebar-div.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger .ant-menu-dark .ant-menu-submenu-selected,
.main-sidebar-div.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger .ant-menu-dark .ant-menu-submenu-title:hover {
    color: var(--gray-color);
}

.main-sidebar-div.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger ~ .site-layout-main .ant-layout-content {
    width: calc(100% - 80px);
}

.header-dropdown {
    width: 100px
}

.header-dropdown ul {
    padding: 10px 5px
}

.sl-shc-switch {
    height: 40px;
    border-radius: 11px;
    width: 162px;
    margin-right: 20px;
    background: #edf1f4;
}

.sl-shc-switch::after {
    height: 35px;
    width: 118px !important;
    border-radius: 7px;
}

.sl-shc-switch span {
    margin: 0;
    position: relative;
    color: #57b5a4;
    font-size: 13.5px;
    font-weight: 600;
    z-index: 1;
    text-align: left;
    margin-left: 38px;
    transition: 0s;
}

.sl-shc-switch.ant-switch-checked span {
    text-align: right;
    margin-right: 6px;
}

.sl-shc-switch.ant-switch-checked ~ .hdr-chart-icon {
    left: 48px;
    right: auto;
}

.filter-modal-main {
    top: 30px;
}

.filter-modal-main .ant-modal-body {
    padding-top: 0;
    padding-bottom: 0;
}

.filter-modal-main .filter-label {
    margin: 0;
}

.filter-modal-main .ant-select-selector {
    padding: 5px 9px;
    height: auto;
    border-color: #6e7277 !important;
}

.filter-modal-main .fmmc-deselect {
    font-size: 14px;
    color: #96d0c6;
    font-weight: 500;
    margin-left: 10px;
}

.filter-modal-main .fmm-contain {
    margin-bottom: 12px;
}

.filter-modal-main .fmmc-each-item {
    position: relative;
}

.filter-modal-main .fmmc-each-item > span {
    position: absolute;
    z-index: 1;
    left: 9px;
    background: #fff;
    font-size: 11px;
    top: 9px;
}

.filter-modal-main .ant-modal-body .ant-select {
    margin-top: 18px;
    margin-bottom: 5px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 35px;
}

.chart-container .graph-labels .gl-contain {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
}

.chart-container .gl-contain label {
    font-size: 15px;
    color: #353c41;
    font-weight: 500;
}

.chart-container .gl-contain span {
    font-size: 14px;
    font-weight: 500;
    color: #818d9b;
}

.chart-container .ant-tabs {
    width: 100%;
}

.chart-container .ant-tabs .ant-tabs-bar {
    border: none;
}

.chart-container .ant-tabs-nav-container {
    float: right;
}

.graph-label-container h4 {
    font-size: 18px;
    border-bottom: 1px solid #f1ebeb;
    padding-bottom: 11px;
}

.graph-label-container .glc-footer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #f1ebeb;
    padding-top: 10px;
}

.cc-graph-tabs {
    list-style-type: none;
    text-align: right;
}

.cc-graph-tabs li {
    display: inline-block;
    margin-right: 15px;
    color: #57b5a4;
    font-size: 16px;
    cursor: pointer;
}

.cc-graph-tabs li:first-child {
    border-bottom: 1px solid #57b5a4;
    padding-bottom: 5px;
}

.gl-icon {
    content: "";
    position: absolute;
    left: -33px;
    top: 3px;
    height: 20px;
    width: 20px;
    border-radius: 44px;
}

.detail-table tbody tr td:first-child {
    white-space: nowrap;
}

.graph-title {
    font-size: 18px;
    border-bottom: 1px solid #f1ebeb;
    padding-bottom: 11px;
}

.chart-tabs .ant-tabs-tab-active {
    color: #57b5a4;
}

.chart-tabs .ant-tabs-ink-bar {
    background-color: #57b5a4;
    height: 1px;
}

.plaid-btn {
    padding: 5px 5px;
    height: 36px;
    width: 159px;
    margin: 6px 12px;
    border-radius: 7px;
    border: 1px solid var(--primary-color) !important;
    color: var(--primary-color)!important;
    background: transparent !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
}
.linked-banks .linked-banks-div{
    padding:5px 0;
    border-bottom: 1px solid #d5d5d5;
}
.linked-banks .linked-banks-div:last-child{
    border-bottom: none;
}
.custom-checkbox-table .ant-checkbox-inner {
    width: 20px;
    height: 20px;
}
.custom-checkbox-table .ant-checkbox-inner::after {
    top: 48%;
    left: 30%;
}
.custom-checkbox-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td:first-child{
    vertical-align: middle;
}
.custom-checkbox-table .ant-checkbox-indeterminate .ant-checkbox-inner::after{
    width: 12px;
    height: 12px;
    top: 50%;
    left: 50%;
}
.header-details-left .refresh-btn.ant-btn.filter-green-btn{
    font-size: 20px;
}
.custom-checkbox-select-all.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.custom-checkbox-select-all .ant-checkbox:hover .ant-checkbox-inner,
.custom-checkbox-select-all .ant-checkbox-input:focus + .ant-checkbox-inner{
    border-color: var(--primary-color);
}
.sub-menu-div-inner .custom-checkbox-select-all .ant-checkbox-checked .ant-checkbox-inner{
    background-color: var(--primary-color);
}